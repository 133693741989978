import {
    Container as BSContainer,
    Row as BSRow,
    Col as BSCol,
} from "react-bootstrap";
import { BsInstagram, BsWhatsapp } from 'react-icons/bs';
import { GrMail } from 'react-icons/gr';
import { AiFillPhone } from 'react-icons/ai';

import "../../css/footer/style.css";
import data from '../../info.json';

function mesasgeForURL(msg){
    msg = msg.split(' ')
    msg = msg.join('%20')
  
    return msg
}

function importAll(r) {
    return r.keys().map(r);
}

const images = importAll(
    require.context("../../img/", false, /\.(png|jpe?g|svg)$/)
);
// console.log(images)
function Footer() {
  return (
    <BSContainer fluid className="footer">
        <BSRow>
            <BSCol sm={2} xs={12} className="column d-flex align-items-center flex-row">
                <a href="/" id="toHome">
                    <div className="img-wrapper d-flex align-items-center justify-content-center">
                        <img src={images[12]} alt="Ícone Sun Light"/>
                    </div>
                </a>
                <label
                onClick={e => {
                    document.querySelector('#toHome').click()
                }}
                >
                    Sun Light
                </label>
            </BSCol>
            <BSCol xs={3} className="normal-view column d-flex align-items-center flex-column">
                <p style={{
                    marginBottom:"0",
                }}>Contato</p>
                <span>
                    <a href={`${data.whatsapp.before_number}${data.phones.whatsapp[0]}${data.whatsapp.after_number}${mesasgeForURL('Olá, gostaria de solicitar um orçamento.')}`} target="_blank" rel="noreferrer">
                        <BsWhatsapp className="icon"/>
                    </a>
                    <a href="/contato">
                        <GrMail className="icon"/>
                    </a>
                    <a href="/contato">
                        <AiFillPhone className="icon"/>
                    </a>
                </span>
            </BSCol>
            <BSCol xs={3} className="normal-view column text-center flex-column align-items-center">
                &copy; Copyright 2023 <br /> Sun Light | <a href="https://www.linkedin.com/in/caio-m-silva-5b42a9209" target="_blank" rel="noreferrer"><span>@Caio M. Silva</span></a>
            </BSCol>
            <BSCol xs={3} className="normal-view column d-flex align-items-center justify-content-end">
                <a href="https://www.instagram.com/sunlightengenhariadosol/" target="_blank" rel="noreferrer"><BsInstagram className="bs-icon" style={{
                    marginRight:"5px",
                }}/>Instagram</a>
            </BSCol>
            <BSCol xs={5} className="small-only column d-flex align-items-center flex-column">
                <p style={{
                    marginBottom:"0",
                }}>Contato</p>
                <span>
                    <a href={`${data.whatsapp.before_number}${data.phones.whatsapp[0]}${data.whatsapp.after_number}${mesasgeForURL('Olá, gostaria de solicitar um orçamento.')}`} target="_blank" rel="noreferrer">
                        <BsWhatsapp className="icon"/>
                    </a>
                    <a href="/contato">
                        <GrMail className="icon"/>
                    </a>
                    <a href="/contato">
                        <AiFillPhone className="icon"/>
                    </a>
                </span>
            </BSCol>
            <BSCol xs={5} className="small-only column d-flex align-items-center justify-content-end">
                <a href="https://www.instagram.com/sunlightengenhariadosol/" target="_blank" rel="noreferrer"><BsInstagram className="bs-icon" style={{
                    marginRight:"5px",
                }}/>Instagram</a>
            </BSCol>
            <BSCol xs={12} className="small-only column text-center justify-content-center flex-column">
                <p>&copy; Copyright 2023</p> <p>Sun Light | <a href="https://www.linkedin.com/in/caio-m-silva-5b42a9209" target="_blank" rel="noreferrer"><span>@Caio M. Silva</span></a></p>
            </BSCol>
        </BSRow>
    </BSContainer>
  )
}

export default Footer