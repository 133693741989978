import {
    Container as BSContainer,
    Row as BSRow,
    Col as BSCol,
} from "react-bootstrap";
import { IoLogoWhatsapp } from 'react-icons/io';
import { AiFillPhone } from 'react-icons/ai';
import { FaRegCopy } from 'react-icons/fa';
import { BiLinkExternal } from 'react-icons/bi';
import {useEffect} from 'react';

import data from '../../info.json';
import Map from '../map';
import Form from '../form';
import "../../css/contact/style.css";

function SwitchAlert(){
    console.log('Iniciando')

    const alertElement = document.querySelector('#copy-confirmation2');

    alertElement.classList.remove('hidden')
    alertElement.classList.remove('d-none')
    alertElement.classList.add('show')
    alertElement.classList.add('d-flex')
    
    setTimeout(() => {
        alertElement.classList.remove('show')
        alertElement.classList.remove('d-flex')
        alertElement.classList.add('hidden')
        alertElement.classList.add('d-none')
    }, 2*1000);
}

function mesasgeForURL(msg){
    msg = msg.split(' ')
    msg = msg.join('%20')
  
    return msg
  }

function switchColor(){
    // console.clear()

    const el = document.querySelector('.click-here')
    const style = window.getComputedStyle(el)

    // console.log(style)
    // console.log(Object.values(style))
    // console.log(style["background-color"])
    const color = ["#696969", style["background-color"]]
    el.style.backgroundColor = color[0]

    setTimeout(() => { el.style.backgroundColor = color[1] }, 0.2 * 1000)
}

const Contact = () => {

    // useEffect(matchHeights);

    return (
        <>
            <BSContainer fluid id="container">
                <BSRow className="m-0 p-0">
                    <BSCol id="content-area" xs={12} sm={12} md={12} lg={6}>
                        <BSContainer fluid className="container p-0">
                            <BSRow id="title-row">
                                <p>ENTRE EM CONTATO CONOSCO</p>
                            </BSRow>
                            <BSRow id="number-row">
                                <BSCol xs={5} sm={5} lg={5} md={5} className="wrapper text-center">
                                    <div className="header">WHATSAPP</div>
                                    <div className="body d-flex flex-column">
                                        <div className="inner-wrapper  d-flex align-items-center">
                                            <a target="_blank" rel="noreferrer" href={`${data.whatsapp.before_number}${data.phones.whatsapp[0]}${data.whatsapp.after_number}${mesasgeForURL('Olá, gostaria de solicitar um orçamento.')}`}>
                                                <div className="displayer d-flex align-items-center">
                                                    <IoLogoWhatsapp className="icon" />
                                                    <span>
                                                        {/* 6232897574 */}
                                                        {data.phones["whatsapp"][0]}
                                                    </span>
                                                </div>
                                                <BiLinkExternal className="secondary-icon"/>
                                            </a>
                                        </div>
                                        <div className="inner-wrapper  d-flex align-items-center">
                                            <a target="_blank" rel="noreferrer" href={`${data.whatsapp.before_number}${data.phones.whatsapp[1]}${data.whatsapp.after_number}${mesasgeForURL('Olá, gostaria de solicitar um orçamento.')}`}>
                                                <div className="displayer">
                                                    <IoLogoWhatsapp className="icon" />
                                                    <span>
                                                        {/* 62993023339 */}
                                                        {data.phones["whatsapp"][1]}
                                                    </span>
                                                </div>
                                                <BiLinkExternal className="secondary-icon"/>
                                            </a>
                                        </div>
                                    </div>
                                </BSCol>
                                <BSCol xs={5} sm={5} lg={5} md={5} className="wrapper text-center">
                                    <div id="copy-confirmation2" className="copy-confirmation hidden d-none justify-content-center">Número copiado!</div> 
                                    <div className="header">LIGUE EM</div>
                                    <div className="body d-flex flex-column">
                                        <div className="inner-wrapper d-flex align-items-center"
                                        onClick={
                                            e => {
                                                navigator.clipboard.writeText(data.phones["call-only"][0]);
                                                SwitchAlert()
                                            }
                                        }
                                        >
                                            <div className="displayer d-flex align-items-center">
                                                <AiFillPhone className="icon" />
                                                <span>
                                                    {/* 62996846756 */}
                                                    {data.phones["call-only"][0]}
                                                </span>
                                            </div>
                                            <FaRegCopy className="secondary-icon"/>
                                        </div>
                                        <div className="inner-wrapper d-flex align-items-center"
                                        onClick={
                                            e => {
                                                navigator.clipboard.writeText(data.phones["call-only"][1]);
                                                SwitchAlert()
                                            }
                                        }
                                        >
                                            <div className="displayer">
                                                <AiFillPhone className="icon" />
                                                <span>
                                                    {/* 62985461885 */}
                                                    {data.phones["call-only"][1]}
                                                </span>
                                            </div>
                                            <FaRegCopy className="secondary-icon"/>
                                        </div>
                                    </div>
                                </BSCol>
                            </BSRow>
                            <BSRow id="form-row" className="justify-content-center">
                                <div className="wrapper p-0">
                                    <div className="header text-center">
                                        Entre em contato por email
                                    </div>
                                    <div className="body">
                                        <Form/>
                                    </div>
                                </div>
                            </BSRow>
                        </BSContainer>
                    </BSCol>
                    <BSCol id="map-area" xs={12} sm={12} md={12} lg={6} className="m-0 p-0">
                        <div className="click-here-wrapper"
                        >
                            <div className="click-here"
                            onClick={() => {
                                switchColor()
                                navigator.clipboard.writeText(data.maps.maps_address);
                            }}
                            >
                                CLIQUE AQUI PARA COPIAR A LOCALIZAÇÃO
                            </div>
                        </div>
                        <Map />
                    </BSCol>
                </BSRow>
            </BSContainer>
        </>
    );
}

export default Contact;