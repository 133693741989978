import {
  Container as BSContainer,
  Row as BSRow,
  Col as BSCol,
} from "react-bootstrap";
import { BsBrightnessAltHighFill } from "react-icons/bs";
import { FaSun, FaMoon } from "react-icons/fa";

import Card from "../card";
import "../../css/solarEnergy/style.css";
import data from '../../info.json';

function mesasgeForURL(msg){
    msg = msg.split(' ')
    msg = msg.join('%20')
  
    return msg
}

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(
  require.context("../../img/banners", false, /\.(png|jpe?g|svg)$/)
);

const images2 = importAll(
  require.context("../../img", false, /\.(png|jpe?g|svg)$/)
);

const SEAreaImages = importAll(
  require.context("../../img/SE-areas", false, /\.(png|jpe?g|svg)$/)
);

const SEAdvImages = importAll(
  require.context("../../img/SE-advantages", false, /\.(png|jpe?g|svg)$/)
);

const JobsImages = importAll(
  require.context("../../img/jobs", false, /\.(png|jpe?g|svg)$/)
);

function SEAreaComponents(){
  return SEAreaImages.map((e, i) => (
    <BSCol key={i} className="column" xs={12}>
      <img src={e} alt="" />
    </BSCol>
  ))
}


const solarEnergy = () => {
  console.log(images)
  return(
    <BSContainer id="container" fluid className="d-flex justify-content-center flex-column">
      <BSRow
      id="SE-publi"
      style={{
        backgroundImage:`url(${images[1]})`
      }}
      >
        <div className="inner">
          <strong>Você ainda paga pela sua energia elétrica?</strong>
          <p>Se você quer deixar de ser um <span className="bold">comprador</span> e se tornar um <span className="bold">gerador</span> da sua própria energia elétrica.</p>
          <p>Solicite um orçamento conosco clicando no botão abaixo.</p>
          <div className="button"><a href={`${data.whatsapp.before_number}${data.phones.whatsapp[1]}${data.whatsapp.after_number}${mesasgeForURL('Olá, gostaria de solicitar um orçamento.')}`}>SOLICITAR ORÇAMENTO</a></div>
        </div>
      </BSRow>
      <BSRow
      id="SE-intro"
      className="d-flex justify-content-center"
      style={{
        backgroundImage:`url(${images2[5]})`
      }}
      >
        <BSCol xs={12} className="column" name="title">
          Deixe o Sol trabalhar por você
        </BSCol>
        <BSCol xs={12} className="column">
          <BsBrightnessAltHighFill />
          <p>Te ajudamos a reduzir até 95% de sua conta de energia, ajudando a preservar o meio ambiente</p>
        </BSCol>
      </BSRow>
      <BSRow className="button-row">
        <div className="button"><a href={`${data.whatsapp.before_number}${data.phones.whatsapp[1]}${data.whatsapp.after_number}${mesasgeForURL('Olá, gostaria de solicitar um orçamento.')}`}>SOLICITAR ORÇAMENTO</a></div>
      </BSRow>
      <BSRow id="SE-areas"
      style={{
        backgroundImage:`url(${images[5]})`
      }}
      >
        {SEAreaComponents()}
      </BSRow>
      <BSRow className="button-row">
        <div className="button"><a href={`${data.whatsapp.before_number}${data.phones.whatsapp[1]}${data.whatsapp.after_number}${mesasgeForURL('Olá, gostaria de solicitar um orçamento.')}`}>SOLICITAR ORÇAMENTO</a></div>
      </BSRow>
      <BSRow id="SE-advantages" className="d-flex justify-content-center">
        <BSCol className="column title text-center">VANTAGENS</BSCol>
      </BSRow>
      <BSRow xs={12} id="adv-wrapper"
      className="m-0 p-0"
      style={{
        backgroundImage:`url(${images2[7]}), url(${images2[2]}), url(${images2[9]}), url(${images2[4]})`
      }}
      >
        <BSCol id="paint-layer" className="m-0">
          <BSContainer fluid id="adv-grid">
            <BSRow className="justify-content-center">
              <BSCol sm={4} xs={12} className="column">
                <img className="adv-img" src={SEAdvImages[0]} alt="" />
              </BSCol>
              <BSCol sm={4} xs={12} className="column">
                <img className="adv-img" src={SEAdvImages[1]} alt="" />
              </BSCol>
              <BSCol sm={4} xs={12} className="column">
                <img className="adv-img" src={SEAdvImages[2]} alt="" />
              </BSCol>
            </BSRow>
            <BSRow className="justify-content-center">
              <BSCol sm={4} xs={12} className="column">
                <img className="adv-img" src={SEAdvImages[3]} alt="" />
              </BSCol>
              <BSCol sm={4} xs={12} className="column">
                <img className="adv-img" src={SEAdvImages[4]} alt="" />
              </BSCol>
              <BSCol sm={4} xs={12} className="column">
                <img className="adv-img" src={SEAdvImages[5]} alt="" />
              </BSCol>
            </BSRow>
            <BSRow className="justify-content-center">
              <BSCol sm={4} xs={12} className="column">
                <img className="adv-img" src={SEAdvImages[6]} alt="" />
              </BSCol>
              <BSCol sm={4} xs={12} className="column">
                <img className="adv-img" src={SEAdvImages[7]} alt="" />
              </BSCol>
              <BSCol sm={4} xs={12} className="column">
                <img className="adv-img" src={SEAdvImages[8]} alt="" />
              </BSCol>
            </BSRow>
          </BSContainer>
        </BSCol>
      </BSRow>
      <BSRow className="button-row">
        <div className="button"><a href={`${data.whatsapp.before_number}${data.phones.whatsapp[1]}${data.whatsapp.after_number}${mesasgeForURL('Olá, gostaria de solicitar um orçamento.')}`}>SOLICITAR ORÇAMENTO</a></div>
      </BSRow>
      <BSRow id="SE-funcionality"
      style={{
        backgroundImage:`url(${images[2]})`
      }}
      >
        <BSCol xs={12} className="column text-center">
          <h4>Funcionamento</h4>
          <p>A energia não é gerada pelo calor, e sim pela irradiação da luz solar sobre as placas</p>
          <p>Todos os dados sobre a produção do equipamento pode ser facilmente monitorado através na internet utilizando seu smartphone</p>
        </BSCol>
        <BSCol xs={12} id="cards-wrapper" className="column text-center">
        <Card
        title-icon={<FaSun className="icon" style={{
          color:"#fff",
        }}/>}
        title=""
        subtitle=""
        className="functionality-card bold"
        background-color="#F3D225"
        text-color="#fff"
        text={
        <>
          <span>Assim que o sol nasce a produção de energia das placas começam a abastecer o consumo elétrico dos equipamentos da sua rede</span>
          <br /><span>
            A energia produzida que não for imediatamente consumida irá para a distribuidora e te renderá créditos para consumo
          </span>
        </>
        }
        />
        <Card
        title-icon={<FaMoon className="icon" style={{
          color:"#fff",
        }}/>}
        title=""
        subtitle=""
        className="functionality-card bold"
        background-color="#255C99"
        text-color="#fff"
        text={
        <>
          <span>A falta de produção durante a noite é compensada pelo uso dos créditos vindos da geração excedente feita durante o dia</span>
        </>
        }
        />
        </BSCol>
      </BSRow>
      <BSRow className="button-row">
        <div className="button"><a href={`${data.whatsapp.before_number}${data.phones.whatsapp[1]}${data.whatsapp.after_number}${mesasgeForURL('Olá, gostaria de solicitar um orçamento.')}`}>SOLICITAR ORÇAMENTO</a></div>
      </BSRow>
    </BSContainer>
  )
}

export default solarEnergy;