import {Carousel as BSCarousel} from 'react-bootstrap';

import '../../css/carousel/style.css'

function Items(images){

  return images.map((e, i) => (
    <BSCarousel.Item key={i}>
      <img src={e} alt="" />
    </BSCarousel.Item>
  ))
}

const Carousel = ({images}) => {

    return (
      <BSCarousel interval={null}>
        {Items(images)}
      </BSCarousel>
    );
}

export default Carousel
