import React from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

import "../../css/map/style.css";
import data from '../../info.json';

const containerStyle = {
  width: '100%',
  height: '100%'
};

const center = {
  lat: -16.715799249062005,
  lng: -49.2982311414151
};

const mapsAPIKey = data.maps.mapsAPIKey;

// function SwitchAlert(){
//     console.log('Iniciando')

//     const alertElement = document.querySelector('#copy-confirmation1');

//     alertElement.classList.remove('hidden')
//     alertElement.classList.add('show')
    
//     setTimeout(() => {
//         alertElement.classList.remove('show')
//         alertElement.classList.add('hidden')
//     }, 2*1000);
// }
function SwitchAlert(){
  console.log('Iniciando')

  const alertElement = document.querySelector('#copy-confirmation1');

  alertElement.classList.remove('hidden')
  alertElement.classList.remove('d-none')
  alertElement.classList.add('show')
  alertElement.classList.add('d-flex')
  
  setTimeout(() => {
      alertElement.classList.remove('show')
      alertElement.classList.remove('d-flex')
      alertElement.classList.add('hidden')
      alertElement.classList.add('d-none')
  }, 2*1000);
}

function Map() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: mapsAPIKey
  })

  const [map, setMap] = React.useState(null)

  return (
    <>
      <div id="copy-confirmation1" className="copy-confirmation hidden d-flex justify-content-center">Localização copiada!</div>
      <div id="map-wrapper">
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={17}
          >
            <Marker
              className="map-pinner"
              position={center}
              options={{
                label: {
                  text: "Sun Light",
                  className: "map-marker",
                },
              }}
              onClick={(e) => {
                navigator.clipboard.writeText(data.maps.maps_address);
                SwitchAlert()
              }}
            />
          </GoogleMap>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default React.memo(Map)