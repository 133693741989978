import Carousel from "../carousel";
import Card from "../card";
import '../../css/cardCarousel/style.css'

function displayName(meta){
    let {displayName, address, potency, estimation, pj} = meta
    const arr_l = displayName.toLowerCase().split(' ')
    const arr = displayName.split(' ')

    if(arr_l[0] === 'fazenda'){
        return arr[1]
    }

    if(pj){
        return displayName
    }

    if(meta['extension'] !== undefined){
        return `${meta['extension']['name']} - Extensão`
    }

    return arr[0]
}

function MetaInfo(info){
    return (
        <span className="project-info">
            <span><strong>Localidade</strong>: {info.address}</span><br />
            <span><strong>Potência instalada</strong>: {info.potency}</span><br />
            <span><strong>Estimativa da produção</strong>: {info.estimation}</span>
        </span>
    )
}

const CardCarousel = ({images, meta}) => {
    // console.log(meta)
    return (
        <Card
        title-icon = {
            <Carousel
            images={images}
            />
        }
        title = {displayName(meta)}
        className = "carousel-card"
        text = { MetaInfo(meta) }
        />
    );
}

export default CardCarousel;