import {
  Navbar as BSNavbar,
  Container as BSContainer,
  Nav as BSNav,
} from "react-bootstrap";

import "../../css/navbar/style.css";

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(
  require.context("../../img", false, /\.(png|jpe?g|svg)$/)
);

// images.forEach((e, i) => {console.log(i, e)})

function Navbar() {
  return (
    <BSNavbar expand="lg" className="p-0">
      <BSContainer fluid className="navbar-container justify-content-between">
        <div className="navbar-icon d-flex">
          <a href="/"><img src={images[13]} alt="" /></a>
        </div>
        <BSNavbar.Toggle aria-controls="basic-navbar-nav" id="colapsable" />
        <BSNavbar.Collapse id="basic-navbar-nav">
          <BSNav className="d-flex justify-content-end">
            <BSNav.Link href="/">Página inicial</BSNav.Link>
            <BSNav.Link href="/energia-solar">Energia Solar</BSNav.Link>
            <BSNav.Link href="/projetos">Projetos</BSNav.Link>
            <BSNav.Link href="/contato">Contato</BSNav.Link>
            <BSNav.Link href="/sobre">Sobre</BSNav.Link>
          </BSNav>
        </BSNavbar.Collapse>
      </BSContainer>
    </BSNavbar>
  );
}

export default Navbar;
