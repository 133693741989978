import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
  
} from "react-router-dom";

import App from './components/app';
import Navbar from './components/navbar';
import Footer from './components/footer';
import Projects from './components/projects';
import Contact from './components/contact';
import WhatsappIcon from './components/whatsapp';
import About from './components/about';
import Debug from './components/debug';
import SolarEnergy from './components/solarEnergy';

let DEBBUGING = false;

function Component(child){
  // console.clear()
  return (
    <>
      <WhatsappIcon />
      {DEBBUGING?<Debug />:<></>}
      <header>
        <Navbar/>
      </header>
      {child}
      <footer>
        <Footer/>
      </footer>
    </>
  );
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path='/' element={ Component(<App />) } />
      <Route path='projetos' element={ Component(<Projects />) } />
      <Route path='contato' element={ Component(<Contact />) } />
      <Route path='sobre' element={ Component(<About />) } />
      <Route path='energia-solar' element={ Component(<SolarEnergy />) } />
    </>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
document.title = 'Sun Light';

root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);