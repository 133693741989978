import {useEffect} from 'react';

import "../../css/debug/style.css";

function displayDimensions(event){
    const body = document.querySelector('body')
    let str = `${window.innerWidth}`;
    str += ` x ${window.innerHeight}`;
    // let str = `${body.innerWidth}`;
    // str += ` x ${body.innerHeight}`;

    document.querySelector('#debug').innerHTML = str;
}

window.onresize = displayDimensions;

const Debug = () => {
    useEffect(displayDimensions,)

  return(
    <div id="debug-wrapper">
      <div id="debug"></div>
    </div>
  )
}

export default Debug;