import {
    Container as BSContainer,
    Row as BSRow,
    Col as BSCol,
} from "react-bootstrap";

import '../../css/about/style.css'

function importAll(r) {
    return r.keys().map(r);
  }
  
const images = importAll(
    require.context("../../img/banners", false, /\.(png|jpe?g|svg)$/)
);

const About = () => {
    
  return(
    <BSContainer fluid id="about-page" className="m-0">
        <BSRow id="title" className="d-flex justify-content-center text-center"
        style={{
            backgroundImage:`url(${images[3]})`
        }}
        >
            <BSCol className="column">
                <h4>Sun Light Engenharia</h4>
                <p>SOBRE A NOSSA EMPRESA</p>
            </BSCol>
        </BSRow>
        <BSRow className="contents" id="content-1">
            <p>A Sun Light Engenharia do Sol é uma empresa de que fornece soluções completas para o desenvolvimento de projetos e execução de obras ligada a todo o campo da engenharia civil.</p>
        </BSRow>
        <BSRow className="contents" id="content-2"
        style={{
            backgroundImage:`url(${images[6]})`
        }}
        >
            <BSCol className="wrapper">
                A partir do ano de 2015, a empresa desenvolveu um foco em projetos ligados a energias sustentáveis, incluindo os de energia fotovoltaica, tendo já executado diversas usinas solares de pequeno, médio e grande porte em todo o estado de Goiás.
            </BSCol>
        </BSRow>
        <BSRow className="contents d-flex justify-content-center" id="content-3"
        style={{
            backgroundImage:`url(${images[4]})`
        }}
        >
            <BSCol className="column text-center">
                <p>Com equipamentos de última geração e seu corpo técnico multidisciplinar, que conta com arquitetos, engenheiros eletricistas e engenheiros civis altamente capacitados.</p>
                <br />
                <p>A Sun Light Engenharia do Sol vem a cada ano quebrando barreiras e se destacando no cenário da engenharia no estado de Goiás, buscando sempre oferecer as melhores soluções para os seus clientes.</p>
            </BSCol>
        </BSRow>
    </BSContainer>
  )
}

export default About;