import data from '../../info.json';
import "../../css/whatsapp/style.css";
import { useEffect } from 'react';

function mesasgeForURL(msg){
  msg = msg.split(' ')
  msg = msg.join('%20')

  return msg
}


const WhatsappIcon = () => {

  useEffect(() => {
    let img = document.querySelector("img");
    let c = 0;

    while (img == null && c < 1000) {
      img = document.querySelector("img");
      c++;

      console.log(`${c} e ${img}`);
    }

    if (img != null) {
      img.src = require("../../img/wp-img-green.png");
    }
  });

    return (
      <div id='wp-fixed-icon' className='minimized'      >
        <a target="_blank" rel="noreferrer" href={`${data.whatsapp.before_number}${data.phones.whatsapp[1]}${data.whatsapp.after_number}${mesasgeForURL('Olá, gostaria de solicitar um orçamento.')}`}>
          <div className="content d-flex">
            <img src='' alt="" />
            <div className="text hidden">Whatsapp</div>
          </div>
        </a>
      </div>
    );
}

export default WhatsappIcon;