import {Card as BSCard} from 'react-bootstrap';

import '../../css/card/style.css'

function Card({className, title, subtitle, 'title-icon':titleIcon, text, 'background-color':backgroundColor, 'text-color':textColor}) {
  const classes = className?className.split(" "):[];

  return (
    <BSCard className={className} style={{
      backgroundColor:backgroundColor,
    }}>
      <BSCard.Body>
        <BSCard.Title>
          {titleIcon?titleIcon:null}
          <p>{title}</p>
        </BSCard.Title>
        <BSCard.Subtitle className={classes.includes('info-card')?'text-center':''}>
          {subtitle}
        </BSCard.Subtitle>
        {
          text?<BSCard.Text style={{
            color:textColor,
          }}>
                {text}
              </BSCard.Text>:null
        }
      </BSCard.Body>
    </BSCard>
  );
}

export default Card;