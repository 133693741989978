import {
    Container as BSContainer,
    Row as BSRow,
    Col as BSCol,
} from "react-bootstrap";

import CardCarousel from "../cardCarousel";
import "../../css/projects/style.css";
import {data} from '../../img/exports';

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(
  require.context("../../img/banners", false, /\.(png|jpe?g|svg)$/)
);

const imageGroupIndexes = Object.keys(data);//array

function Carousels(){
  const arr = [...imageGroupIndexes];
  let res = null
  
  return arr.map(index => {
    let imageArray = Object.values(data[index].images)
    
    return <CardCarousel
    images = { [...imageArray] }
    meta = { data[index].meta }
    />
  })
}

function CarouselsGroups(){
  let res = []
  const carousels = Carousels()
  let keyFlag = true

  for (let i = 0; i < carousels.length-1; i+= 2){
    res.push(
      <BSRow key={keyFlag?i:i-1}>
        <BSCol sm={6} xs={12} className="d-flex justify-content-center">{carousels[i]}</BSCol>
        <BSCol sm={6} xs={12} className="d-flex justify-content-center">{carousels[i+1]}</BSCol>
      </BSRow>
    )

    keyFlag = !keyFlag
  }

  return res.map(e => e)
}

const Projects = () => {

  return (
    <BSContainer fluid className="projects-container p-0 m-0">
      <BSRow className="banner text-center" style={{
        backgroundImage:`url(${images[0]})`,
      }}>
        <h4>PROJETOS EXECUTADOS</h4>
      </BSRow>
      <BSRow className="decorator"></BSRow>
      {CarouselsGroups()}
    </BSContainer>
  )
}

export default Projects;
