import {
  Container as BSContainer,
  Row as BSRow,
  Col as BSCol,
} from "react-bootstrap";
import { useRef, useState } from 'react';

import data from '../../info.json';
import "../../css/app/style.css";

function mesasgeForURL(msg){
  msg = msg.split(' ')
  msg = msg.join('%20')

  return msg
}

function importAll(r) {
  return r.keys().map(r);
}

const JobsImages = importAll(
  require.context("../../img/jobs", false, /\.(png|jpe?g|svg)$/)
);

const JobsImagesDetail = importAll(
  require.context("../../img/jobs/detail", false, /\.(png|jpe?g|svg)$/)
);

function App() {
  const detailRef1 = useRef(null);
  const detailRef2 = useRef(null);
  const detailRef3 = useRef(null);
  const detailRef4 = useRef(null);
  const detailRef5 = useRef(null);

  return (
    <BSContainer fluid className="App m-0 p-0 d-flex justify-content-center flex-column"
    style={{
      backgroundImage:`url(${JobsImages[5]})`
    }}
    >
      <div className="alpha-layer">
        <BSRow id="title" className="text-center d-flex align-content-center">
          <p>Soluções em <span className="color-yellow">Engenharia</span></p>
        </BSRow>
        <BSRow id="jobs">
          <BSCol xs={12} className="column"
          onClick={(e) => {
            let el = document.querySelector('.detail:nth-of-type(1)')
            console.log(el)
            el.focus()
            detailRef1.current?.scrollIntoView()
          }}
          >
            <img src={JobsImages[0]} alt="" />
          </BSCol>
          <BSCol xs={12} className="column"
          onClick={(e) => {
            let el = document.querySelector('.detail:nth-of-type(2)')
            console.log(el)
            el.focus()
            detailRef2.current?.scrollIntoView()
          }}
          >
            <img src={JobsImages[1]} alt="" />
          </BSCol>
          <BSCol xs={12} className="column"
          onClick={(e) => {
            let el = document.querySelector('.detail:nth-of-type(3)')
            console.log(el)
            el.focus()
            detailRef3.current?.scrollIntoView()
          }}
          >
            <img src={JobsImages[3]} alt="" />
          </BSCol>
          <BSCol xs={12} className="column"
          onClick={(e) => {
            let el = document.querySelector('.detail:nth-of-type(4)')
            console.log(el)
            el.focus()
            detailRef4.current?.scrollIntoView()
          }}
          >
            <img src={JobsImages[4]} alt="" />
          </BSCol>
          <BSCol xs={12} className="column"
          onClick={(e) => {
            let el = document.querySelector('.detail:nth-of-type(5)')
            console.log(el)
            el.focus()
            detailRef5.current?.scrollIntoView()
          }}
          >
            <img src={JobsImages[2]} alt="" />
          </BSCol>
        </BSRow>
        <BSRow id="jobs-detail">
          <BSCol className="detail"
          xs={10} lg={10} sm={10}
          ref={detailRef1}
          >
            <div className="color">
              <h5 className="title">PROJETO ESTRUTURAL</h5>
            </div>
            <div className="content"
            style={{
              backgroundImage:`url(${JobsImagesDetail[0]})`
            }}
            >
                <div className="wrapper">
                  <p>O projeto estrutural é fundamental para evitar problemas como trincas e desmoronamento em uma edificação e até mesmo gastos de obra desnecessários. Seja ela de pequeno, médio ou grande porte, esse projeto é de grande importância.</p>
                </div>
            </div>
          </BSCol>
          <BSCol className="detail"
          xs={10} lg={10} sm={10}
          ref={detailRef2}
          >
            <div className="color">
              <h5 className="title">PROJETO DE ARQUITETURA</h5>
            </div>
            <div className="content"
            style={{
              backgroundImage:`url(${JobsImagesDetail[1]})`
            }}
            >
                <div className="wrapper">
                  <p>Um projeto de arquitetura começa com um estudo, tendo como objetivo definir a melhor forma de construir e organizar os espaços de uma casa ou edifício, de acordo com as necessidades de quem vai usá-lo.</p>
                  <p>Ao desenvolver o projeto, o arquiteto precisa considerar diversos fatores, incluindo as características do local (tamanho do terreno, topografia e regulamentação local), a funcionalidade dos espaços, a beleza e harmonia das formas, o conforto das pessoas (incluindo conforto visual, térmico, acústico, entre outros aspectos), as técnicas construtivas e materiais que serão usados, as leis e normas técnicas que precisam ser seguidas, e o custo e o prazo da obra.</p>
                </div>
            </div>
          </BSCol>
          <BSCol className="detail"
          xs={10} lg={10} sm={10}
          ref={detailRef3}
          >
            <div className="color">
              <h5 className="title">LAUDOS TÉCNICOS</h5>
            </div>
            <div className="content"
            style={{
              backgroundImage:`url(${JobsImagesDetail[3]})`
            }}
            >
                <div className="wrapper">
                  <p>O laudo técnico é um documento emitido por um profissional especialista onde é feita uma avaliação detalhada do empreendimento, apresentando o nível da construção, se o perito identificou algum problema, mesmo que simples, como resolver essas falhas e impedir que se agravem.</p>
                </div>
            </div>
          </BSCol>
          <BSCol className="detail"
          xs={10} lg={10} sm={10}
          ref={detailRef4}
          >
            <div className="color">
              <h5 className="title">ENERGIAS RENOVÁVEIS</h5>
            </div>
            <div className="content"
            style={{
              backgroundImage:`url(${JobsImagesDetail[2]})`
            }}
            >
                <div className="wrapper">
                  <p>As energias renováveis são o presente e, ao mesmo tempo, o futuro da produção mundial de eletricidade. O termo "renovável" incorpora a essência desse tipo de energia: a capacidade de estar disponível na natureza e se regenerar continuamente, sem intervenção humana, espontaneamente e em uma quantidade inesgotável.</p>
                </div>
            </div>
          </BSCol>
          <BSCol className="detail"
          xs={10} lg={10} sm={10}
          ref={detailRef5}
          >
            <div className="color">
              <h5 className="title">PROJETOS COMPLEMENTARES</h5>
            </div>
            <div className="content"
            style={{
              backgroundImage:`url(${JobsImagesDetail[4]})`
            }}
            >
              <div className="wrapper">
                <p>São chamados de projetos complementares os projetos elétrico, hidrossanitário, luminotécnico, climatização, proteção contra descargas atmosféricas (SPDA), proteção contra incêndio, cabeamento estruturado, áudio de vídeo, entre outros. Todos estes projetos devem seguir as normas vigentes estabelecidas pela Associação Brasileira de Normas Técnicas (ABNT).</p>
                <ul>
                  <li>No projeto elétrico, você encontrará informações detalhadas das instalações elétricas da obra, sobre a utilização dos pontos elétricos, as cargas da edificação e sua distribuição, a segurança da instalação e o padrão de alimentação.</li>
                  <li>O projeto hidrossanitário é aquele que engloba toda a distribuição de água fria, água quente, esgoto e água pluvial ao longo da edificação. Ele é essencial para que a água que vem da concessionária chegue até as peças de utilização, como chuveiro, torneiras, etc.</li>
                  <li>O projeto de cabeamento é um método padronizado de construir uma rede de internet, informática e telefonia, seguindo normas nacionais e internacionais, a fim de otimizar a segurança, eficiência e vida útil do sistema.</li>
                </ul>
              </div>
            </div>
          </BSCol>
        </BSRow>
        <BSRow id="home-contact-area" className="justify-content-center">
        </BSRow>
      </div>
    </BSContainer>
  );
}

export default App;